import React from "react";

function DetailedView({ rowData, onClose }) {
  return (
    <div className="auth-wrapper">
      <div className="auth-inner" style={{ textAlign: "center", width: "70%" }}>
        <h4>Details for {rowData.name}</h4>
        <table style={{textAlign : 'center', margin : 'auto'}}>
          <tbody>
            <tr style={{marginBottom : '10px'}}>
              <th>Service Provider:</th>
              <td>{rowData.name}</td>
            </tr>
            <tr>
              <th>Description:</th>
              <td>{rowData.description}</td>
            </tr>
            <tr>
              <th>Comment:</th>
              <td>{rowData.comment}</td>
            </tr>
            <tr>
              <th>Company Address:</th>
              <td>{rowData.companyAddress}</td>
            </tr>
            <tr>
              <th>Company Email:</th>
              <td>{rowData.companyEmail}</td>
            </tr>
            <tr>
              <th>Company Phone Number:</th>
              <td>{rowData.companyPhoneNumber}</td>
            </tr>
            <tr>
              <th>Company URL:</th>
              <td>{rowData.companyUrl}</td>
            </tr>
            <tr>
              <th>Pickup From Airport To Home:</th>
              <td>{rowData.pickupFromAirportToHome}</td>
            </tr>
            <tr>
              <th>Pickup From Home To Airport:</th>
              <td>{rowData.pickupFromHomeToAirport}</td>
            </tr>
          </tbody>
        </table>
        <button className="btn btn-secondary" onClick={onClose} style={{marginTop : '20px'}}>
          Close
        </button>
      </div>
    </div>
  );
}

export default DetailedView;