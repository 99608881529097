import React, { useState, useRef, useEffect } from "react";
import DataTable from "react-data-table-component";
import DetailedView from "./DetailedView.component";
import Navbar from "./Navbar.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { addForm, fetchAirportName } from "../services/fetchData";
import { useNavigate } from "react-router";
import Multiselect from "multiselect-react-dropdown";
import { countries } from "countries-list"; // Import countries from the countries-list package
import Select from "react-select";

function CustomDataTable({ data, fetchAndNavigate }) {
  const navigate = useNavigate();

  const [showForm, setShowForm] = useState(false); // State to control the visibility of the add entry form
  const [entryData, setEntryData] = useState({
    name: "",
    description: "",
    comment: "",
    companyUrl: "",
    companyAddress: "",
    companyPhoneNumber: null,
    phoneCode: null,
    companyEmail: "",
    noOfMiles: null,
    selectedAreas: [],
    selectedCountry: "", // Add selectedCountries state
    serviceClass: {
      threePessanger: {
        isNA: true,
        classType: "",
        numOfVehicle: "",
      },
      fourPessanger: {
        isNA: true,
        classType: "",
        numOfVehicle: "",
      },
      fivePessanger: {
        isNA: true,
        classType: "",
        numOfVehicle: "",
      },
      sevenToNinePessanger: {
        isNA: true,
        classType: "",
        numOfVehicle: "",
      },
    },
  });
  const [apiOptions, setApiOptions] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // Add selectedRow state
  const addEntryButtonRef = useRef(null);
  const [naChecked, setNaChecked] = useState({
    threePessanger: true,
    fourPessanger: true,
    fivePessanger: true,
    sevenToNinePessanger: true,
  });

  const [error, setError] = useState("");
  const [successmsg, setSuccessmsg] = useState("");

  useEffect(() => {
    // Fetch options from your API
    const fetchOptions = async () => {
      try {
        const data = await fetchAirportName();
        setApiOptions(data);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);

  const handleViewClick = (row) => {
    setSelectedRow(row); // Set the selected row when "View" is clicked
  };

  // Function to handle showing/hiding the add entry form
  const toggleForm = () => {
    setShowForm(!showForm);
  };

  // Function to handle form input changes
  // ...

  const handleInputChange = (e, serviceClassName) => {
    const { name, value, type, checked } = e.target;

    if (type === "radio") {
      setEntryData({ ...entryData, [name]: value });
    } else if (type === "checkbox") {
      setEntryData({
        ...entryData,
        serviceClass: {
          ...entryData.serviceClass,
          [name]: {
            ...entryData.serviceClass[name],
            isNA: checked,
            classType: checked ? "" : entryData.serviceClass[name].classType,
            numOfVehicle: checked
              ? ""
              : entryData.serviceClass[name].numOfVehicle,
          },
        },
      });

      setNaChecked({
        ...naChecked,
        [name]: checked,
      });

      // If isNA is true, reset values for other properties
      if (!checked) {
        setEntryData((prevEntryData) => ({
          ...prevEntryData,
          serviceClass: {
            ...prevEntryData.serviceClass,
            [name]: {
              ...prevEntryData.serviceClass[name],
              classType: "",
              numOfVehicle: "",
            },
          },
        }));
      }
    } else {
      // Check if the input is related to service class
      if (name === "classType" || name === "numOfVehicle") {
        // Set values only if the checkbox is unchecked
        if (!naChecked[serviceClassName]) {
          setEntryData((prevEntryData) => ({
            ...prevEntryData,
            serviceClass: {
              ...prevEntryData.serviceClass,
              [serviceClassName]: {
                ...prevEntryData.serviceClass[serviceClassName],
                [name]: value,
              },
            },
          }));
        }
      } else {
        // Ensure only numeric values are entered for companyPhoneNumber
        if (name === "companyPhoneNumber" || name === "noOfMiles") {
          const numericValue = value.replace(/\D/g, ""); // Remove non-numeric characters
          if (/^\d{0,10}$/.test(numericValue)) {
            setEntryData({
              ...entryData,
              [name]: numericValue === "" ? null : parseInt(numericValue, 10),
            });
          }
        } else {
          // For other input fields, set the value directly
          setEntryData({ ...entryData, [name]: value });
        }
      }
    }
  };

  const handleCloseFormButton = () => {
    setShowForm(false);
  };

  // Function to handle saving an entry (you can replace this with your API call)
  const handleSaveEntry = () => {
    if (
      !entryData.name ||
      !entryData.description ||
      !entryData.comment ||
      !entryData.companyAddress ||
      !entryData.companyEmail ||
      !entryData.noOfMiles ||
      !entryData.companyPhoneNumber ||
      !entryData.phoneCode ||
      !entryData.companyUrl ||
      !entryData.selectedCountry
    ) {
      setError("Please enter all fields.");
      return;
    } else {
      if (entryData.selectedAreas.length === 0) {
        setError("Please select at least one option for selectedAreas.");
        return;
      }
      async function asyncHandleSubmitForm() {
        try {
          // const args ={phone , password}
          const res = await addForm(entryData);
          if (res.status == 201) {
            setError("");
            setSuccessmsg("Login Successfully");
            setTimeout(() => {
              fetchAndNavigate();
              setSuccessmsg("");
              navigate("/dashboard");
            }, 500);
          }
        } catch {}
      }
      asyncHandleSubmitForm();
      setEntryData({
        name: "",
        description: "",
        comment: "",
        companyUrl: "",
        companyAddress: "",
        companyPhoneNumber: null,
        phoneCode: null,
        companyEmail: "",
        noOfMiles: null,
        selectedAreas: [],
        selectedCountry: "",
        serviceClass: {
          threePessanger: {
            isNA: true,
            classType: "",
            numOfVehicle: "",
          },
          fourPessanger: {
            isNA: true,
            classType: "",
            numOfVehicle: "",
          },
          fivePessanger: {
            isNA: true,
            classType: "",
            numOfVehicle: "",
          },
          sevenToNinePessanger: {
            isNA: true,
            classType: "",
            numOfVehicle: "",
          },
        },
      });
      setShowForm(false);
    }
  };

  const countryOptions = Object.entries(countries).map(([code, info]) => ({
    value: code,
    label: info.name,
    phone: info.phone[0],
  }));

  const handleCountryChange = (selectedOption) => {
    if (selectedOption) {
      const phoneCode = "+" + selectedOption.phone;
      setEntryData({
        ...entryData,
        selectedCountry: selectedOption.label,
        phoneCode: phoneCode,
      });
    } else {
      setEntryData({
        ...entryData,
        selectedCountry: "",
        phoneCode: null,
      });
    }
  };

  const columns = [
    {
      name: "Service Provider",
      selector: "name",
      sortable: true,
    },
    {
      name: "Description",
      selector: "description",
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <button onClick={() => handleViewClick(row)}>
          <FontAwesomeIcon icon={faEye} />
        </button>
      ),
      button: true,
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="mb-3">
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
      <div className="mb-3">
        {successmsg && <div className="alert alert-success">{successmsg}</div>}
      </div>
      {showForm ? (
        <div className="auth-wrapper">
          <div className="auth-inner" style={{ width: "80%" }}>
            <h4 style={{ marginBottom: "15px", textAlign: "center" }}>
              Add New Service Provider
            </h4>
            <div className="container">
              <form>
                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Service Provider Name:</label>
                  <input
                    type="text"
                    name="name"
                    value={entryData.name}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Description:</label>
                  <input
                    type="text"
                    name="description"
                    value={entryData.description}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>
                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Servicable Area:</label>
                  <Multiselect
                    options={apiOptions.map((option) => ({
                      value: option.id,
                      label: option.name,
                    }))}
                    selectedValues={entryData.selectedAreas}
                    onSelect={(selectedList) =>
                      setEntryData({
                        ...entryData,
                        selectedAreas: selectedList,
                      })
                    }
                    onRemove={(selectedList) =>
                      setEntryData({
                        ...entryData,
                        selectedAreas: selectedList,
                      })
                    }
                    displayValue="label"
                  />
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Select Country:</label>
                  <Select
                    options={countryOptions}
                    value={countryOptions.find(
                      (option) => option.label === entryData.selectedCountry
                    )}
                    onChange={handleCountryChange}
                    isSearchable
                    placeholder="Select Country"
                  />
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Company URL:</label>
                  <input
                    type="text"
                    name="companyUrl"
                    value={entryData.companyUrl}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Company Address:</label>
                  <input
                    type="text"
                    name="companyAddress"
                    value={entryData.companyAddress}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Company Phone Number:</label>
                  <div className="input-group">
                    <input
                      type="text"
                      name="phoneCode"
                      value={entryData.phoneCode}
                      onChange={handleInputChange}
                      className="form-controls input-group-prepend input-group-text"
                      readOnly
                      style={{ width: "5em" }}
                    />
                    <input
                      type="tel" // Change type to 'tel'
                      name="companyPhoneNumber"
                      value={entryData.companyPhoneNumber}
                      onChange={handleInputChange}
                      className="form-control"
                      placeholder="Phone Number"
                      // Set the maximum length
                      pattern="\d{10}"
                    />
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Company Email Address:</label>
                  <input
                    type="email"
                    name="companyEmail"
                    value={entryData.companyEmail}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>No Of Miles servicing:</label>
                  <input
                    type="number"
                    name="noOfMiles"
                    value={entryData.noOfMiles}
                    onChange={handleInputChange}
                    className="form-control"
                  />
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>Site And Class:</label> <br />
                  <h6 style={{ display: "inline-block", marginRight: "20px" }}>
                    3 Pessenger{" "}
                  </h6>{" "}
                  NA{" "}
                  <input
                    type="checkbox"
                    name="threePessanger"
                    checked={entryData.serviceClass.threePessanger.isNA}
                    onChange={(e) => handleInputChange(e, "threePessanger")}
                  />
                  <div className="row">
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`classType`}
                        disabled={naChecked.threePessanger}
                        onChange={(e) => handleInputChange(e, "threePessanger")}
                      >
                        <option value="">Class Type</option>
                        <option value="economic">Economic</option>
                        <option value="business">Business</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`numOfVehicle`}
                        disabled={naChecked.threePessanger}
                        onChange={(e) => handleInputChange(e, "threePessanger")}
                      >
                        <option value="">Please Select an Option</option>
                        <option value="0-10">0-10</option>
                        <option value="10-20">10-20</option>
                        <option value="20-30">20-30</option>
                        <option value="30-40">30-40</option>
                        <option value="40-50">40-50</option>
                        {/* Add other options as needed */}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <h6 style={{ display: "inline-block", marginRight: "20px" }}>
                    4 Pessenger{" "}
                  </h6>{" "}
                  NA{" "}
                  <input
                    type="checkbox"
                    name="fourPessanger"
                    checked={entryData.serviceClass.fourPessanger.isNA}
                    onChange={(e) => handleInputChange(e, "fourPessanger")}
                  />
                  <div className="row">
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`classType`}
                        disabled={naChecked.fourPessanger}
                        onChange={(e) => handleInputChange(e, "fourPessanger")}
                      >
                        <option value="">Class Type</option>
                        <option value="economic">Economic</option>
                        <option value="business">Business</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`numOfVehicle`}
                        disabled={naChecked.fourPessanger}
                        onChange={(e) => handleInputChange(e, "fourPessanger")}
                      >
                        <option value="">Please Select an Option</option>
                        <option value="0-10">0-10</option>
                        <option value="10-20">10-20</option>
                        <option value="20-30">20-30</option>
                        <option value="30-40">30-40</option>
                        <option value="40-50">40-50</option>
                        {/* Add other options as needed */}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <h6 style={{ display: "inline-block", marginRight: "20px" }}>
                    5 Pessenger{" "}
                  </h6>{" "}
                  NA{" "}
                  <input
                    type="checkbox"
                    name="fivePessanger"
                    checked={entryData.serviceClass.fivePessanger.isNA}
                    onChange={(e) => handleInputChange(e, "fivePessanger")}
                  />
                  <div className="row">
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`classType`}
                        disabled={naChecked.fivePessanger}
                        onChange={(e) => handleInputChange(e, "fivePessanger")}
                      >
                        <option value=""> Class Type</option>
                        <option value="economic">Economic</option>
                        <option value="business">Business</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`numOfVehicle`}
                        disabled={naChecked.fivePessanger}
                        onChange={(e) => handleInputChange(e, "fivePessanger")}
                      >
                        <option value="">Please Select an Option</option>
                        <option value="0-10">0-10</option>
                        <option value="10-20">10-20</option>
                        <option value="20-30">20-30</option>
                        <option value="30-40">30-40</option>
                        <option value="40-50">40-50</option>
                        {/* Add other options as needed */}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <h6 style={{ display: "inline-block", marginRight: "20px" }}>
                    7 - 9 Pessenger{" "}
                  </h6>{" "}
                  NA{" "}
                  <input
                    type="checkbox"
                    name="sevenToNinePessanger"
                    checked={entryData.serviceClass.sevenToNinePessanger.isNA}
                    onChange={(e) =>
                      handleInputChange(e, "sevenToNinePessanger")
                    }
                  />
                  <div className="row">
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`classType`}
                        disabled={naChecked.sevenToNinePessanger}
                        onChange={(e) =>
                          handleInputChange(e, "sevenToNinePessanger")
                        }
                      >
                        <option value="">Class Type</option>
                        <option value="economic">Economic</option>
                        <option value="business">Business</option>
                      </select>
                    </div>
                    <div className="col-lg-6">
                      <select
                        className="form-control"
                        name={`numOfVehicle`}
                        disabled={naChecked.sevenToNinePessanger}
                        onChange={(e) =>
                          handleInputChange(e, "sevenToNinePessanger")
                        }
                      >
                        <option value="">Please Select an Option</option>
                        <option value="0-10">0-10</option>
                        <option value="10-20">10-20</option>
                        <option value="20-30">20-30</option>
                        <option value="30-40">30-40</option>
                        <option value="40-50">40-50</option>
                        {/* Add other options as needed */}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label style={{ marginTop: "10px" }}>
                    Pickup from home to airport:
                  </label>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="pickupFromHomeToAirport"
                        value="Yes"
                        checked={entryData.pickupFromHomeToAirport === "Yes"}
                        onChange={handleInputChange}
                      />{" "}
                      Yes
                    </label>
                    <label style={{ marginLeft: "10px" }}>
                      <input
                        type="radio"
                        name="pickupFromHomeToAirport"
                        value="No"
                        checked={entryData.pickupFromHomeToAirport === "No"}
                        onChange={handleInputChange}
                      />{" "}
                      No
                    </label>
                  </div>
                </div>
                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label style={{ marginTop: "10px" }}>
                    Pickup from airport to home:
                  </label>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="pickupFromAirportToHome"
                        value="Yes"
                        checked={entryData.pickupFromAirportToHome === "Yes"}
                        onChange={handleInputChange}
                      />{" "}
                      Yes
                    </label>
                    <label style={{ marginLeft: "10px" }}>
                      <input
                        type="radio"
                        name="pickupFromAirportToHome"
                        value="No"
                        checked={entryData.pickupFromAirportToHome === "No"}
                        onChange={handleInputChange}
                      />{" "}
                      No
                    </label>
                  </div>
                </div>

                <div className="form-group" style={{ marginBottom: "15px" }}>
                  <label>comment:</label>
                  <textarea
                    rows="6"
                    cols="40"
                    name="comment"
                    value={entryData.comment}
                    onChange={handleInputChange}
                    className="form-control"
                  ></textarea>
                </div>
                <div
                  className="form-group container"
                  style={{ marginTop: "10px" }}
                >
                  <button
                    type="button"
                    className="btn btn-success"
                    onClick={handleSaveEntry}
                  >
                    Save
                  </button>

                  <button
                    style={{ marginLeft: "10%" }}
                    type="button"
                    className="btn btn-danger"
                    onClick={handleCloseFormButton}
                  >
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : selectedRow ? (
        // Show the detailed view component when a row is selected
        <DetailedView
          rowData={selectedRow}
          onClose={() => setSelectedRow(null)} // Close the detailed view
        />
      ) : (
        <div className="container">
          <div className="d-flex justify-content-end mb-2">
            <button
              style={{ marginTop: "10px" }}
              ref={addEntryButtonRef}
              className="btn btn-primary"
              onClick={toggleForm}
            >
              Add Service Provider
            </button>
          </div>
          <DataTable
            title="Data Table"
            columns={columns}
            data={data}
            pagination
            highlightOnHover
            noHeader
          />
        </div>
      )}
    </div>
  );
}

export default CustomDataTable;
