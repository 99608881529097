import React, { useState, useEffect } from "react";
import CustomDataTable from "./datatable.component";
import { fetchData } from "../services/fetchData";
import { useNavigate } from "react-router";

function Dashboard() {
  const [data, setData] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn"))
  );
  const navigate = useNavigate();

  async function fetchDataAsync() {
    try {
      const result = await fetchData();
      setData(result);
    } catch (error) {}
  }

  useEffect(() => {
    if (!isLoggedIn) {
      setIsLoggedIn(false);
      localStorage.setItem("isLoggedIn", false);
      navigate("/");
    }
    fetchDataAsync();
  }, []);

  return (
    <div>
      <CustomDataTable data={data} fetchAndNavigate={fetchDataAsync} />
    </div>
  );
}

export default Dashboard;
