import React,{ useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Login from './components/login.component';
import SignUp from './components/signup.component';
import Dashboard from './components/dashboard.component';


function App() {

    const [isLoggedIn,setIsLoggedIn] = useState(JSON.parse(localStorage.getItem('isLoggedIn')))
  return (
    <Router>
      <div className="App">        
          <div >
            <div>
              <Routes>
                <Route exact path="/" element={isLoggedIn ?<Dashboard /> : <Login />} />
                <Route path="/sign-in" element={<Login />} />
                <Route path="/sign-up" element={<SignUp />} />
                <Route path="/dashboard" element={<Dashboard />} /> {/* Add the Dashboard route */}
              </Routes>
            </div>
          </div>

      </div>
    </Router>
  );
}

export default App;
