import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { signIn } from "../services/fetchData";
import logoImage from "../Assets/Images/Logo.png"; // Import your image here


function Login() {
  const navigate = useNavigate();

  const [error, setError] = useState("");
  const [successmsg, setSuccessmsg] = useState("");

  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn"))
  );

  useEffect(() => {
    if (isLoggedIn) {
      console.log(
        "🚀 ~ file: login.component.js:20 ~ useEffect ~ isLoggedIn:",
        isLoggedIn
      );
      navigate("/dashboard");
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "mobile") {
      setMobile(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  async function asyncHandleSubmit() {
    try {
      setError("");
      const res = await signIn({ mobile, password });
      console.log("🚀 ~ file: login.component.js:42 ~ asyncHandleSubmit ~ res:", res)
      if (res.status == 201) {
        setSuccessmsg("Login Successfully");

        localStorage.setItem("isLoggedIn", true);
        setTimeout(() => {
          navigate("/dashboard"); // Redirect to the dashboard page
        }, 1000);
      }
    } catch(err) {
      if(err.response.status == 406){
        setError("Your Account is Unapproved.");    
        return
      }else{
        setError("Invalid credentials. Please try again."); // Display an error message for unsuccessful login
      }

    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!mobile || !password) {
      setError("Please enter all fields.");
      return;
    } else {
      asyncHandleSubmit();
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">

      <img src={logoImage} alt="Logo" className="logo-image" /> 

        <div className="mb-3">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="mb-3">
          {successmsg && (
            <div className="alert alert-success">{successmsg}</div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <h3>Sign In</h3>
          <div className="mb-3">
            <label>Phone Number</label>
            <input
              type="number"
              name="mobile"
              className="form-control"
              placeholder="Enter Phone Number"
              value={mobile}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Enter password"
              value={password}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3"></div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
          <p className="forgot-password text-right">
            Don't Have An Account <a href="/sign-up">sign up?</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Login;
