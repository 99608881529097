import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { signUp } from "../services/fetchData";
import logoImage from "../Assets/Images/Logo.png"; // Import your image here

function SignUp() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(
    JSON.parse(localStorage.getItem("isLoggedIn"))
  );
  const [error, setError] = useState("");
  const [successmsg, setSuccessmsg] = useState("");

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "firstName") {
      setFirstName(value);
    } else if (name === "lastName") {
      setLastName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "mobile") {
      setMobile(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };
  async function asyncHandleSubmit() {
    try {
      setError("");

      const args = { firstName, lastName, email, mobile, password };
      const res = await signUp(args);

      if (res.status == 201) {
        setSuccessmsg("Registered Successfully");
        setTimeout(() => {
          navigate("/sign-in"); // Redirect to the sign-in page
        }, 1000);
      }
    } catch (err){
      if(err.response.status == 406){
        setError("These Credentials already Exist.");    
        return
      }
    }
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!firstName || !lastName || !email || !mobile || !password) {
      setError("Please enter all fields.");
      return;
    } else {
      asyncHandleSubmit();
    }
  };

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
      <img src={logoImage} alt="Logo" className="logo-image" /> 

        <div className="mb-3">
          {error && <div className="alert alert-danger">{error}</div>}
        </div>
        <div className="mb-3">
          {successmsg && (
            <div className="alert alert-success">{successmsg}</div>
          )}
        </div>

        <form onSubmit={handleSubmit}>
          <h3>Sign Up</h3>
          <div className="mb-3">
            <label>First name</label>
            <input
              type="text"
              name="firstName"
              className="form-control"
              placeholder="First name"
              value={firstName}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label>Last name</label>
            <input
              type="text"
              name="lastName"
              className="form-control"
              placeholder="Last name"
              value={lastName}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label>Email address</label>
            <input
              type="email"
              name="email"
              className="form-control"
              placeholder="Enter email"
              value={email}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label>Phone Number</label>
            <input
              type="number"
              name="mobile"
              className="form-control"
              placeholder="Enter phone number"
              value={mobile}
              onChange={handleInputChange}
            />
          </div>
          <div className="mb-3">
            <label>Password</label>
            <input
              type="password"
              name="password"
              className="form-control"
              placeholder="Enter password"
              value={password}
              onChange={handleInputChange}
            />
          </div>
          <div className="d-grid">
            <button type="submit" className="btn btn-primary">
              Sign Up
            </button>
          </div>
          <p className="forgot-password text-right">
            Already registered <a href="/sign-in">sign in?</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default SignUp;
