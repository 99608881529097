import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import logoImage from "../Assets/Images/Logo.png"; // Import your image here


function Navbar() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState( JSON.parse(localStorage.getItem("isLoggedIn")));

  
  const onLogout = () => {
    setIsLoggedIn(false);
    localStorage.setItem("isLoggedIn", false);
    navigate("/sign-in");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
        <img src={logoImage} style={{maxHeight:"60px",maxHeight:"60px",marginLeft: "0%"}} alt="Logo" className="logo-image" /> 
        
        </Link>
        <div style={{ marginLeft: "88%" }} className="collapse navbar-collapse">
          <ul className="navbar-nav ml-auto">
            {isLoggedIn ? (
              <>
                <li className="nav-item">
                  <button
                    className="btn btn btn-outline-danger"
                    onClick={onLogout}
                  >
                    Logout
                  </button>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item">
                  <Link className="nav-link" to="/sign-in">
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/sign-up">
                    Sign Up
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
