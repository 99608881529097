import axios from 'axios';
const backendUrl = process.env.REACT_APP_BACKEND_URL;


const fetchData = async () => {
  
  try {
    const response = await axios.get(`${backendUrl}/entry`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const fetchAirportName = async () => {
  
  try {
    const response = await axios.get(`${backendUrl}/airportNames`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const signUp = async (args) => {
 const response=  await axios.post(`${backendUrl}/user/signup`, args)
  return response
}

const signIn = async (args) => {
  const response=  await axios.post(`${backendUrl}/user/signin`, args)
  return response

}

const addForm = async (args) => {
  const response =  await axios.post(`${backendUrl}/entry`, args)
  return response
  
}

// export default fetchData;
export { fetchData, signUp ,signIn ,addForm, fetchAirportName };



